@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'regular';
  font-style: normal;
  font-weight: 400;
  src: url("./assets/font/Roboto/Roboto-Regular.ttf") format("truetype");
}
@font-face {
  font-family: 'thin';
  font-weight: 400;
  src: url("./assets/font/Roboto/Roboto-Light.ttf") format("truetype");
}
@font-face {
  font-family: 'light';
  font-weight: 400;
  src: url("./assets/font/Roboto/Roboto-Light.ttf") format("truetype");
}
@font-face {
  font-family: 'bold';
  font-weight: 400;
  src: url("./assets/font/Roboto/Roboto-Bold.ttf") format("truetype");
}
  html {
    scroll-behavior: smooth;
  }

  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  
  .no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  * {
    color: #333333;
  }
.radial-progress {
  position: relative;
  display: inline-grid;
  height: var(--size);
  width: var(--size);
  place-content: center;
  border-radius: 9999px;
  background-color: transparent;
  vertical-align: middle;
  box-sizing: content-box;
  --value: 0;
  --size: 5rem/* 80px */;
  --thickness: calc(var(--size) / 10);
}